.header {
	/* border-bottom: 1px solid #777; */
}

.dataset {
	padding: .5rem;
	border: 1px solid #777;
	border-top: 0;
}

.dataset {
	border: 1px solid #777;
}

.title {
	font-size: 1.1rem;
}

.desc {
	font-weight: 200;
	font-size: .9rem;
	padding: .2rem 0;
}

.label {
	font-weight: 600;
	margin-right: .5rem;
}

.search {
	background: inherit;
	outline: 0;
	border: 0;
	border-bottom: 1px solid #777;
	font-size: 1.1rem;
	margin-bottom: 1rem;
}

.search:focus {
	outline: 0;
	background: inherit;
}

.cursorPointer {
	cursor: pointer;
}

.list {
	list-style: none;
	text-indent: 0;
	padding: 0;
}

.datatype_label {
	background-color: #b70000;
}

.bg_gray {
	background-color: #E3E3E3;
}